@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
  &.swiperCard{
    padding: 0 8px;
    .imageWrapper{
        img{
          left: 0;
        }
    }
  }
  &.listCardView {
    flex-direction: row;
    gap: 25px;
   
    padding-bottom: 24px;
   
      .imageWrapper {
        flex: 0 0 30%;
        max-width: 30%;
       
        .featuredWishList{       
          padding: 8px;
        }
     
     
    }
    .info{
      flex: 1;
      padding: 0; 
      .buttonWrapper{
        button{
          min-height: 40px;
          max-width: max-content;
          min-width: unset;
          padding: 0 15px;
          font-size: 14px;
        }
      }
      .title{
          font-size: 18px;
          line-height: 20px;
      }
       }
    &:not(:last-child){
      border-bottom: 1px solid #e4e9ee;
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  margin: 8px 0;
    color: #000000;
    font-family: Sora;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    width: calc(100% - 57px);
    word-break: break-all;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.imageWrapper {
  position: relative;
  .featuredWishList {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 21px 0;
    .tagName {
      background: #111111bf;
      font-family: Sora;
      font-size: 14px;
      font-weight: 300;
      line-height: 22.4px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
      padding: 8px 20px;
      border-radius: 30px;
    }
    .wishListActive{ 
      border: 0;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      transition: background-color 0.2s linear;
      cursor: pointer;
      /* background: #fff; */
      display: flex;
            justify-content: center;
            align-items: center; 
            padding: 0;

      background-color: #F34B3F;
      svg {
        fill: #ffffff;
        path {
          stroke: #ffffff;
        }
      }
    }
    .wishList {
      background: #fff;color: transparent;
      border: 0;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      transition: background-color 0.2s linear;
      cursor: pointer;
      width: 36px;
            display: flex;
            justify-content: center;
            align-items: center; 
            padding: 0;
      &:hover {
        background-color: #F34B3F;
        svg {
          fill: #ffffff;
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .productImageBlock_ {
    & > div {
      position: relative !important;
      padding-top: 60%;
      border-radius: 12px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.info {
  .reviewCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .category {
      font-family: Sora;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #666666;
    }
    .desktopReviewRating {
      span {
        color: #212121;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-right: 4px;
      }
    }
    .mainInfo {
      .title {
        font-family: Sora;
        font-size: 24px;
        font-weight: 700;
        line-height: 33.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .authorInfo {
      }
    }
  }

  .buttonWrapper {
    display: flex;
    gap: 16px;
    margin-top: 24px;
    button {
      flex: 1;
      min-height: 56px;
      padding: 0;
      min-width: unset;
      &:disabled{
        background: #c8c8c8;
    border-color: #c8c8c8;
    color: #939191;
    pointer-events: none;
    cursor: not-allowed !important;
      }
      
    }
  }
}

